<template>
  <b-modal id="modal-contact-us" scrollable centered hide-header hide-footer>
    <button
      type="button"
      @click="$bvModal.hide('modal-contact-us')"
      class="close"
    >
      <img src="@/assets/img/ic-close.svg" alt="" />
    </button>
    <h2 class="roboto text-center">Contact us</h2>
    <div :class="{ 'opacity-3': isLoading }">
      <MyInputFormText
        id="name"
        label="Nama"
        placeholder="Nama"
        v-model.trim="$v.formData.name.$model"
        :classComponent="{
          'is-invalid-custom': $v.formData.name.$error,
          'is-valid-custom': !$v.formData.name.$invalid,
        }"
        :invalidMessage="[
          !$v.formData.name.required ? 'Butuh nama kamu nih' : null,
          !$v.formData.name.namePattern ? 'Masukin text yu!' : null,
        ]"
        :redAsterisk="true"
        :disabled="userData"
      />
      <MyInputFormText
        id="email"
        label="Email"
        placeholder="Email"
        v-model.trim="$v.formData.email.$model"
        :classComponent="{
          'is-invalid-custom': $v.formData.email.$error,
          'is-valid-custom': !$v.formData.email.$invalid,
        }"
        :invalidMessage="[
          !$v.formData.email.required ? 'Butuh email kamu nih' : null,
          !$v.formData.email.email ? 'Masukan format email kamu' : null,
        ]"
        :redAsterisk="true"
        :disabled="userData"
      />
      <MyInputFormText
        id="phone"
        label="Phone Number"
        placeholder="Phone Number"
        v-model.trim="$v.formData.phone_number.$model"
        :classComponent="{
          'is-invalid-custom': $v.formData.phone_number.$error,
          'is-valid-custom': !$v.formData.phone_number.$invalid,
        }"
        :invalidMessage="[
          !$v.formData.phone_number.required
            ? 'Butuh nomor telepon kamu nih'
            : null,
          !$v.formData.phone_number.phonePattern
            ? 'Nomor telepon kamu tidak valid :('
            : null,
          !$v.formData.phone_number.minLength &&
          $v.formData.phone_number.phonePattern
            ? `Nomor telepon kamu terlalu pendek nih, panjang teks minimum ${$v.formData.phone_number.$params.minLength.min}.`
            : null,
          !$v.formData.phone_number.maxLength &&
          $v.formData.phone_number.phonePattern
            ? `Nomor telepon kamu terlalu panjang nih, panjang teks maksimum ${$v.formData.phone_number.$params.maxLength.max}.`
            : null,
        ]"
        :redAsterisk="true"
        :phonePrefix="true"
        :disabled="userData?.phone || false"
      />
      <MyInputFormSelect
        id="category"
        label="Category"
        v-model="$v.formData.category.$model"
        :options="categoryOptions"
        :classComponent="{
          'is-invalid-custom': $v.formData.category.$error,
          'is-valid-custom': !$v.formData.category.$invalid,
        }"
        :invalidMessage="[
          !$v.formData.category.required
            ? 'Butuh jenis pertanyaan kamu nih'
            : null,
        ]"
        :redAsterisk="true"
      >
        <template #placeholder>
          Jenis Pertanyaan <span class="text-danger">*</span>
        </template>
      </MyInputFormSelect>
      <b-form-textarea
        id="message"
        label="Apa yang bisa kami bantu?"
        placeholder="Apa yang bisa kami bantu?"
        v-model="$v.formData.message.$model"
        :class="{
          'is-invalid-custom': $v.formData.message.$error,
          'is-valid-custom': !$v.formData.message.$invalid,
        }"
        type="textarea"
        rows="4"
        :redAsterisk="true"
      />
      <small class="d-block text-grey text-right">
        {{ formData.message.length }}/{{ messageMaxLength }}
      </small>
      <div class="d-flex mb-3">
        <div class="img-review-container">
          <label for="insert-review-img">
            <div
              v-if="formFile.file?.type == 'application/pdf'"
              class="d-flex flex-column h-0 justify-content-center"
            >
              <font-awesome
                icon="file-pdf"
                class="position-relative"
                size="2x"
              />
              <small class="position-relative">{{ formFile.file.name }}</small>
            </div>
            <img
              v-else
              class="cursor-pointer"
              :src="formFile.path ?? require('@/assets/img/icon-upload.png')"
              alt=""
            />
          </label>
          <fawesome-pro
            v-if="formFile.file"
            variant="far"
            icon="times"
            class="review-img-delete cursor-pointer"
            @click="clearFormFile"
          />
          <input
            type="file"
            id="insert-review-img"
            class="d-none"
            accept="image/*, .pdf"
            @input="uploadFile"
          />
        </div>
      </div>
    </div>
    <button class="btn btn-wz-gold btn-block" @click="onSubmit">
      Submit <font-awesome v-if="isLoading" icon="spinner" spin />
    </button>
  </b-modal>
</template>
<script>
import { regex } from "@/_helpers";
import MyInputFormSelect from "../form/MyInputFormSelect";
import MyInputFormText from "../form/MyInputFormText";
import {
  required,
  email,
  helpers,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";

const namePattern = helpers.regex("namePattern", regex.namePattern),
  phonePattern = helpers.regex("phonePattern", regex.phonePattern);

export default {
  name: "ModalContactUs",
  components: {
    MyInputFormSelect,
    MyInputFormText,
  },
  data() {
    return {
      isLoading: false,
      formData: {
        name: null,
        phone_number: null,
        email: null,
        message: "",
        category: null,
      },
      messageMaxLength: 250,
      categoryOptions: [
        { value: 1, text: "Seputar Whizliz" },
        { value: 2, text: "Error pada Website" },
        { value: 3, text: "Kritik & Saran" },
        { value: 4, text: "Lainnya" },
      ],
      formFile: {
        path: null,
        file: null,
      },
    };
  },
  computed: {
    userData() {
      return this.$store.getters.getUserData;
    },
  },
  validations() {
    return {
      formData: {
        name: { required, namePattern },
        phone_number: {
          required,
          phonePattern,
          minLength: minLength(9),
          maxLength: maxLength(14),
        },
        email: { required, email },
        message: {
          required,
          maxLength: maxLength(this.messageMaxLength),
        },
        category: { required, numeric },
      },
    };
  },
  mounted() {
    this.resetFormData();
    if (this.userData) this.setFormData();
  },
  methods: {
    resetFormData() {
      this.formData.name = null;
      this.formData.email = null;
      this.formData.phone_number = null;
      this.formData.message = "";
      this.formData.category = null;
      this.clearFormFile();
      this.$nextTick(() => {
        this.$v.formData.$reset();
      });
    },
    setFormData() {
      this.formData.name = this.userData.full_name;
      this.formData.email = this.userData.email;
      this.formData.phone_number = this.userData.phone.replace("+62", "");
    },
    clearFormFile() {
      this.formFile = {
        path: null,
        file: null,
      };
    },
    uploadFile(event) {
      const file = event.target.files[0];

      if (
        !["image/png", "image/jpeg", "application/pdf"].includes(
          file["type"]
        ) &&
        file
      )
        return this.$helpers.toaster.make(
          "File must be an image or PDF document",
          "danger"
        );
      if (file.size >= 3078000)
        return this.$helpers.toaster.make(
          "File size must be less than 3.078 MB",
          "danger"
        );

      //* Add / update file to array reviewImage.file
      this.formFile.file = file;

      //* Add / update path image for preview image to array reviewImage.path
      if (file.type != "application/pdf") {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.formFile.path = e.target.result;
        };
        reader.readAsDataURL(file);
      }
      this.$emit("input", file);
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        this.$v.$touch();
        if (this.$v.$error) {
          this.$helpers.toaster.make(
            "Please enter the form correctly",
            "danger"
          );
          this.isLoading = false;
          return console.error(
            Object.keys(this.$v.formData).reduce((arr, key) => {
              if (!key.includes("$") && this.$v.formData[key].$invalid)
                arr.push(key);
              return arr;
            }, [])
          );
        }
        const form = new FormData();
        form.append("data", JSON.stringify({
          ...this.formData,
          message: this.formData.message.trim(),
          phone_number: `+62${this.formData.phone_number}`
        }));
        form.append("report_file", this.formFile.file);
        const res = await this.$api.user.sendReport(form);
        if (res.status === 200) {
          // this.$v.$reset();
          this.resetFormData();
          if (this.userData) this.setFormData();
          const h = this.$createElement;
          this.$helpers.toaster.make(
            "Terimakasih sudah menghubungi Whizliz, laporan atau pertanyaan kamu akan segera kami proses",
            "success",
            h(
              "div",
              {
                class: ["d-flex", "flex-grow-1", "align-items-baseline"],
              },
              [h("strong", {}, "Pesan kamu telah kami terima!")]
            )
          );
          this.$bvModal.hide("modal-contact-us");
        }
      } catch (e) {
        if (e.response) this.$helpers.toaster.make(e.response, "danger");
        console.error(e);
      }
      this.isLoading = false;
    },
  },
  watch: {
    userData(newVal) {
      if (newVal) {
        this.setFormData();
      } else {
        this.resetFormData();
      }
    },
  },
};
</script>