var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-contact-us","scrollable":"","centered":"","hide-header":"","hide-footer":""}},[_c('button',{staticClass:"close",attrs:{"type":"button"},on:{"click":function($event){return _vm.$bvModal.hide('modal-contact-us')}}},[_c('img',{attrs:{"src":require("@/assets/img/ic-close.svg"),"alt":""}})]),_c('h2',{staticClass:"roboto text-center"},[_vm._v("Contact us")]),_c('div',{class:{ 'opacity-3': _vm.isLoading }},[_c('MyInputFormText',{attrs:{"id":"name","label":"Nama","placeholder":"Nama","classComponent":{
        'is-invalid-custom': _vm.$v.formData.name.$error,
        'is-valid-custom': !_vm.$v.formData.name.$invalid,
      },"invalidMessage":[
        !_vm.$v.formData.name.required ? 'Butuh nama kamu nih' : null,
        !_vm.$v.formData.name.namePattern ? 'Masukin text yu!' : null,
      ],"redAsterisk":true,"disabled":_vm.userData},model:{value:(_vm.$v.formData.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.name, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.formData.name.$model"}}),_c('MyInputFormText',{attrs:{"id":"email","label":"Email","placeholder":"Email","classComponent":{
        'is-invalid-custom': _vm.$v.formData.email.$error,
        'is-valid-custom': !_vm.$v.formData.email.$invalid,
      },"invalidMessage":[
        !_vm.$v.formData.email.required ? 'Butuh email kamu nih' : null,
        !_vm.$v.formData.email.email ? 'Masukan format email kamu' : null,
      ],"redAsterisk":true,"disabled":_vm.userData},model:{value:(_vm.$v.formData.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.formData.email.$model"}}),_c('MyInputFormText',{attrs:{"id":"phone","label":"Phone Number","placeholder":"Phone Number","classComponent":{
        'is-invalid-custom': _vm.$v.formData.phone_number.$error,
        'is-valid-custom': !_vm.$v.formData.phone_number.$invalid,
      },"invalidMessage":[
        !_vm.$v.formData.phone_number.required
          ? 'Butuh nomor telepon kamu nih'
          : null,
        !_vm.$v.formData.phone_number.phonePattern
          ? 'Nomor telepon kamu tidak valid :('
          : null,
        !_vm.$v.formData.phone_number.minLength &&
        _vm.$v.formData.phone_number.phonePattern
          ? `Nomor telepon kamu terlalu pendek nih, panjang teks minimum ${_vm.$v.formData.phone_number.$params.minLength.min}.`
          : null,
        !_vm.$v.formData.phone_number.maxLength &&
        _vm.$v.formData.phone_number.phonePattern
          ? `Nomor telepon kamu terlalu panjang nih, panjang teks maksimum ${_vm.$v.formData.phone_number.$params.maxLength.max}.`
          : null,
      ],"redAsterisk":true,"phonePrefix":true,"disabled":_vm.userData?.phone || false},model:{value:(_vm.$v.formData.phone_number.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.phone_number, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.formData.phone_number.$model"}}),_c('MyInputFormSelect',{attrs:{"id":"category","label":"Category","options":_vm.categoryOptions,"classComponent":{
        'is-invalid-custom': _vm.$v.formData.category.$error,
        'is-valid-custom': !_vm.$v.formData.category.$invalid,
      },"invalidMessage":[
        !_vm.$v.formData.category.required
          ? 'Butuh jenis pertanyaan kamu nih'
          : null,
      ],"redAsterisk":true},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_vm._v(" Jenis Pertanyaan "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}]),model:{value:(_vm.$v.formData.category.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.category, "$model", $$v)},expression:"$v.formData.category.$model"}}),_c('b-form-textarea',{class:{
        'is-invalid-custom': _vm.$v.formData.message.$error,
        'is-valid-custom': !_vm.$v.formData.message.$invalid,
      },attrs:{"id":"message","label":"Apa yang bisa kami bantu?","placeholder":"Apa yang bisa kami bantu?","type":"textarea","rows":"4","redAsterisk":true},model:{value:(_vm.$v.formData.message.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.message, "$model", $$v)},expression:"$v.formData.message.$model"}}),_c('small',{staticClass:"d-block text-grey text-right"},[_vm._v(" "+_vm._s(_vm.formData.message.length)+"/"+_vm._s(_vm.messageMaxLength)+" ")]),_c('div',{staticClass:"d-flex mb-3"},[_c('div',{staticClass:"img-review-container"},[_c('label',{attrs:{"for":"insert-review-img"}},[(_vm.formFile.file?.type == 'application/pdf')?_c('div',{staticClass:"d-flex flex-column h-0 justify-content-center"},[_c('font-awesome',{staticClass:"position-relative",attrs:{"icon":"file-pdf","size":"2x"}}),_c('small',{staticClass:"position-relative"},[_vm._v(_vm._s(_vm.formFile.file.name))])],1):_c('img',{staticClass:"cursor-pointer",attrs:{"src":_vm.formFile.path ?? require('@/assets/img/icon-upload.png'),"alt":""}})]),(_vm.formFile.file)?_c('fawesome-pro',{staticClass:"review-img-delete cursor-pointer",attrs:{"variant":"far","icon":"times"},on:{"click":_vm.clearFormFile}}):_vm._e(),_c('input',{staticClass:"d-none",attrs:{"type":"file","id":"insert-review-img","accept":"image/*, .pdf"},on:{"input":_vm.uploadFile}})],1)])],1),_c('button',{staticClass:"btn btn-wz-gold btn-block",on:{"click":_vm.onSubmit}},[_vm._v(" Submit "),(_vm.isLoading)?_c('font-awesome',{attrs:{"icon":"spinner","spin":""}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }